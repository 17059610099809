<div>
  <label for="{{ placeholder }}">{{ placeholder }}</label> <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
</div>
<input
  pInputText
  [pKeyFilter]="getInputType()"
  id="{{ placeholder }}"
  [value]="control.value !== null && control.value !== undefined ? control.value : null"
  (input)="valueChanged($event)"
  [attr.readonly]="readOnly ? true : null"
  [attr.disabled]="disabled ? true : null"
/>

<small *ngIf="control.invalid && control.dirty" class="error-label"> {{ errorLabel }} </small>
