import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-week-calendar',
  templateUrl: './form-week-calendar.component.html',
  styleUrl: './form-week-calendar.component.scss',
})
export class FormWeekCalendarComponent implements OnInit {
  @Input() placeholder = '';
  @Input() errorLabel = '';
  @Input() control: FormControl = new FormControl();
  @Input() initialDate?: Date;
  @Input() tooltip?: string;
  @Input() readOnly = false;

  @Output() valueChange = new EventEmitter<string>();

  visualDate: string = '';

  ngOnInit() {
    this.visualDate = this.initialDate?.toDateString() ?? '';
    this.getWeekNumberFromDate();
  }

  public valueChanged() {
    const UTCTime = new Date(new Date(this.visualDate).toUTCString()).toISOString();
    this.valueChange.emit(UTCTime);
    this.getWeekNumberFromDate();
  }

  public reset() {
    this.visualDate = this.initialDate?.toDateString() ?? '';
    this.valueChanged();
  }

  public getWeekNumberFromDate() {
    const date = new Date(this.visualDate);
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const dayOfWeek = (date.getDay() + 6) % 7;
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    const weekNumber = Math.ceil((pastDaysOfYear - dayOfWeek + 1) / 7);
    this.visualDate = `Week ${weekNumber}`;
  }
}
