<div>
  <label for="{{ placeholder }}">{{ placeholder }}</label>
  <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
</div>
<p-calendar
  [showWeek]="true"
  [showIcon]="true"
  [id]="placeholder"
  [firstDayOfWeek]="1"
  [dataType]="'string'"
  [readonlyInput]="true"
  [dateFormat]="'yy-mm-dd'"
  [selectOtherMonths]="true"
  [(ngModel)]="visualDate"
  (ngModelChange)="valueChanged()"
>
  <ng-template pTemplate="date" let-date>
    <div [ngClass]="{ disabled: (visualDate | isPartOfWeek : date) }">{{ date.day }}</div>
  </ng-template>
</p-calendar>

<small *ngIf="control.invalid && control.dirty" class="error-label"> {{ errorLabel }} </small>
