import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from '@shared/types';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnChanges {
  @Input() label = '';
  @Input() showLabel = true;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() filter = false;
  @Input() tooltip?: string;
  @Input() options: DropdownOption[] = [];
  @Input() selectedOptionValue?: string | number;
  @Input() placeholder = this.tr.instant('shared_dropdown_placeholder');
  @Input() errorMessage = this.tr.instant('shared_dropdown_errorMessage');

  @Output() changed = new EventEmitter<DropdownOption>();

  public invalid = false;
  public chosenOption?: DropdownOption;

  constructor(private tr: TranslateService) {}

  public selectOption(option: DropdownOption) {
    this.invalid = false;
    this.changed.emit(option);
  }

  public onHide() {
    this.invalid = this.required && !this.chosenOption;
  }

  public reset(newValue?: unknown) {
    const valueToResetTo = newValue !== null && newValue !== undefined ? newValue : this.selectedOptionValue;
    this.chosenOption = this.options.find((option) => option.value === valueToResetTo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']?.currentValue !== changes['options']?.previousValue) {
      this.chosenOption = this.options.find((option) => option.value === this.selectedOptionValue);
    }
  }
}
