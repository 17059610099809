import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from './localStorage.service';
import { SharedStore } from './shared.store';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private _token = '';

  constructor(private sharedStore: SharedStore, private localStorage: LocalStorageService) {}

  token = {
    get: () => this._token,
    getFromStorage: () => this.localStorage.get('jwtToken'),
    set: (newToken: string) => {
      this._token = newToken;
      this.sharedStore.user.set(jwtDecode(newToken));
      this.localStorage.set('jwtToken', newToken);
    },
    remove: () => {
      this._token = '';
      this.localStorage.set('jwtToken', '');
    },
  };

  isAuthorized(route?: string) {
    if (!route) return false;
    const authRoutes = this.sharedStore.authorizedRoutes.get().map((r) => r.toLowerCase());
    return authRoutes?.includes(route.toLowerCase()) || authRoutes?.includes((route + '_ro').toLowerCase());
  }

  isLoginExpired() {
    const expiration = this.sharedStore.user.get().exp;
    if (expiration && Date.now() < expiration * 1000 - 5000) {
      return false;
    }
    return true;
  }
}
