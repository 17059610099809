import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';

export type TableColumn = { name: string; value: string; sortable?: boolean };
export type TableAction = { label: string; items: { label: string; icon: string; command: () => void }[] };
export type TableType = 'summary' | 'default';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() data: unknown[] = [];
  @Input() type: TableType = 'default';
  @Input() columns: TableColumn[] = [];
  @Input() loading = false;
  @Input() rowHover = true;
  @Input() selectable = false;
  @Input() emptyMessage = this.tr.instant('shared.table.emptyMessage');
  @Input() loadingMessage = this.tr.instant('shared.table.loadingMessage');
  @Input() actions: TableAction[] = [];
  @Input() sortField = 'id';
  @Input() expandable = false;
  @Input() expandColumns: TableColumn[] = [];
  @Input() expandEmptyMessage = this.tr.instant('shared.table.emptyMessage');
  @Input() expandActions: TableAction[] = [];
  @Input() enablePagination = true;

  @ViewChild('pTable') pTable!: Table;

  @Output() rowSelected = new EventEmitter();
  @Output() selection = new EventEmitter();

  selectedItems: unknown[] = [];

  constructor(private tr: TranslateService) {}

  onRowContextSelect(event: unknown) {
    this.rowSelected.emit(event);
  }

  onSelection() {
    this.selection.emit(this.selectedItems);
  }
}
