import { Component, Input } from '@angular/core';
import { SharedStore } from '@shared/services';
import { MeterItem } from 'primeng/metergroup';
import { TimeModel } from 'src/app/features/system/system.types';

enum TimeTypeEnum {
  '#34d399' = 1,
  '#fbbf24' = 2,
  '#60a5fa' = 3,
  '#c084fc' = 4,
  '#e2e8f0' = 5,
}

@Component({
  selector: 'app-time-day-scale',
  templateUrl: './time-day-scale.component.html',
  styleUrl: './time-day-scale.component.scss',
})
export class TimeDayScaleComponent {
  private _times: TimeModel[] = [];

  @Input()
  get times(): TimeModel[] {
    return this._times;
  }
  set times(value: TimeModel[]) {
    this._times = value;
    this.meterTimes = this.computeMeterTimes();
  }

  meterTimes: MeterItem[] = [];
  timeTypes = this.sharedStore.base.get().timeTypes;

  constructor(private sharedStore: SharedStore) {}

  computeMeterTimes(): MeterItem[] {
    const totalSeconds = 24 * 60 * 60;
    const endOfDayInSeconds = 23 * 60 * 60 + 59 * 60 + 59;
    const items: MeterItem[] = [];

    if (this._times.length > 0) {
      // Calculate the percentage for the time from 00:00:00 to the first timeModel
      const firstTimeInSeconds = this.convertTimeToSeconds(this._times[0].from_time);
      const firstLabel =
        this._times[0].from_time === '00:00:00'
          ? this.timeTypes.find((type) => type.id === this._times[0].time_type_id)?.name
          : this.timeTypes.find((type) => type.name === 'Closed')?.name;
      items.push({
        value: Math.round((firstTimeInSeconds / totalSeconds) * 100),
        color: TimeTypeEnum[5],
        label: firstLabel + '\n' + '00:00:00', // this.timeTypes.find((type) => type.id === this._times[0].time_type_id)?.name,
      });

      // Calculate the percentages for the time between each timeModel in the array
      this._times.forEach((time, index) => {
        const currentTimeInSeconds = this.convertTimeToSeconds(time.from_time);
        const nextTimeInSeconds =
          index < this._times.length - 1
            ? this.convertTimeToSeconds(this._times[index + 1].from_time)
            : endOfDayInSeconds;
        const diffInSeconds = nextTimeInSeconds - currentTimeInSeconds;
        const color = TimeTypeEnum[time.time_type_id];
        items.push({
          value: Math.round((diffInSeconds / totalSeconds) * 100),
          color,
          label: this.timeTypes.find((type) => type.id === time.time_type_id)?.name + '\n' + time.from_time,
        });
      });

      // Calculate the total value of all items
      const difference = 100 - items.reduce((total, item) => total + item.value!, 0);
      if (difference > 0.0) {
        items[items.length - 1].value! += difference;
      }
    }

    return items;
  }

  convertTimeToSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 60 * 60 + minutes * 60 + seconds;
  }
}
