<div>
  <label [for]="label.trim()">{{ label.trim() }} </label>
  <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
</div>
<p-inputSwitch
  *ngIf="control"
  id="{{ label.trim() }}"
  [(ngModel)]="control.value"
  [disabled]="disabled"
  (onChange)="valueChanged($event)"
/>
<p-inputSwitch
  *ngIf="signal"
  id="{{ label.trim() }}"
  [(ngModel)]="signal"
  [disabled]="disabled"
  (onChange)="valueChanged($event)"
/>
