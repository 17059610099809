import { Component, EventEmitter, HostBinding, Input, Output, Signal, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrl: './form-checkbox.component.scss',
})
export class FormCheckboxComponent implements OnInit {
  @HostBinding('style.flexDirection') get flexDirection() {
    return this.getFlexDirection();
  }

  @Input() label = '';
  @Input() control?: FormControl;
  @Input() signal?: Signal<boolean>;
  @Input() tooltip = '';
  @Input() disabled: boolean = false;
  @Input() labelPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() initialValue = false;

  @Output() valueChange = new EventEmitter<boolean>();

  public checked = false;

  public ngOnInit() {
    this.checked = this.initialValue;
  }

  private getFlexDirection(): string {
    switch (this.labelPosition) {
      case 'top':
        return 'column';
      case 'right':
        return 'row-reverse';
      case 'left':
        return 'row';
      case 'bottom':
        return 'column-reverse';
      default:
        return 'column';
    }
  }
}
