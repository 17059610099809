import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[allowFromList]' })
export class AllowFromListDirective implements OnChanges {
  allowList: string[] = [];
  keyToTest = '';

  @Input() set allowFromList(value: [string[], string]) {
    [this.allowList, this.keyToTest] = value;
  }
  constructor(private templateRef: TemplateRef<HTMLElement>, private viewContainer: ViewContainerRef) {}

  ngOnChanges() {
    this.viewContainer.clear();
    if (this.allowList.includes(this.keyToTest)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
