<p-table
  #pTable
  dataKey="id"
  [paginator]="data.length > 10"
  [rows]="10"
  [value]="data"
  [loading]="loading"
  [scrollable]="true"
  [rowHover]="true"
  [sortField]="'id'"
  [sortOrder]="1"
  [rowsPerPageOptions]="[10, 25, 50]"
  [(selection)]="selectedItems"
  (selectionChange)="onSelection()"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="expandable"></th>
      <th *ngIf="selectable"><p-tableHeaderCheckbox /></th>
      <th *ngFor="let column of columns" [pSortableColumn]="column.value">
        <ng-container> {{ column.name | translate }}<p-sortIcon [field]="column.value" /></ng-container>
      </th>
      <th *ngIf="actions.length > 0"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="loadingbody">
    <tr>
      <td
        [colSpan]="columns.length + (selectable ? 1 : 0) + (expandable ? 1 : 0) + (!!actions ? 1 : 0)"
        style="text-align: center"
      >
        {{ loadingMessage }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [colSpan]="columns.length + (selectable ? 1 : 0) + (expandable ? 1 : 0) + (!!actions ? 1 : 0)"
        style="text-align: center"
      >
        {{ emptyMessage }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-expanded="expanded">
    <tr>
      <td *ngIf="expandable" class="p-2" style="width: 1rem">
        <p-button
          *ngIf="
            item.children?.length > 0 &&
            (item.children[0].children?.length > 0 || item.children[1].children?.length > 0)
          "
          type="button"
          [text]="true"
          [plain]="true"
          [pRowToggler]="item"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          class="expander"
        />
      </td>
      <td *ngIf="selectable" style="width: 1rem">
        <p-tableCheckbox [value]="item" />
      </td>
      <td
        *ngFor="let column of columns"
        style="height: 48px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
      >
        <ng-container *ngIf="(column.name | translate) !== 'Status'">{{ item[column.value] }}</ng-container>
        <app-status-icon *ngIf="(column.name | translate) === 'Status'" [value]="item[column.value]" />
      </td>
      <td *ngIf="actions.length > 0" style="width: 1rem">
        <app-context-menu [options]="actions" (click)="onRowContextSelect(item)" />
      </td>
    </tr>
    <div *ngIf="!expanded" class="spacer"></div>
  </ng-template>

  <ng-template *ngIf="expandable" pTemplate="rowexpansion" let-item>
    <tr>
      <td class="p-0" [colSpan]="columns.length + 3">
        <p-treeTable [value]="item.children">
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttRow]="rowNode" *ngIf="rowNode.node.children?.length > 0 || rowNode.node.parent">
              <td style="padding: 0 0 0 106px">
                <p-treeTableToggler [rowNode]="rowNode" />
                <span
                  [ngClass]="{
                    'font-bold':
                      rowData.name === 'Facilities' || rowData.name === 'Area Groups' || rowData.name === 'Areas'
                  }"
                >
                  {{ rowData.name }}
                </span>
              </td>
              <td class="py-0" style="width: 72px; height: 40px">
                <app-context-menu
                  *ngIf="rowData.facility_type_id"
                  [options]="actions"
                  (click)="onRowContextSelect(rowData)"
                />
              </td>
            </tr>
          </ng-template>
        </p-treeTable>
      </td>
    </tr>
    <div class="spacer"></div>
  </ng-template>
</p-table>
