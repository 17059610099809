import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { environment } from 'environments/environment';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (!environment.production) {
      //console.warn(`Missing '${params.translateService.currentLang}' translation for: ${params.key}`);
    }
    return params.key;
  }
}
