import { Component, computed, EventEmitter, Input, OnChanges, OnInit, Output, signal } from '@angular/core';
import { SharedStore } from '@shared/services';
import { AreaType } from '@shared/types';
import { Area } from 'src/app/features/devices/devices.types';

type AreaTypeWithColors = AreaType & { point: string; fill: string };

@Component({
  selector: 'app-polygon-shape',
  templateUrl: './polygon-shape.component.html',
  styleUrl: './polygon-shape.component.scss',
})
export class PolygonShapeComponent implements OnInit, OnChanges {
  @Input() area: Area = {} as Area;
  @Input() editable: boolean = false;

  @Output() areaChange = new EventEmitter<Area>();

  private points = signal<Array<Array<number>>>([[]]);
  private pointToDrag: SVGElement | null = null;
  private indexToDrag: number | null = null;
  private maxWidth: number = 816;
  private maxHeight: number = 616;
  private availableTypes = this.sharedStore.base.get().areaTypes.map((areaType) => {
    if (areaType.name === 'Inclusion') return { ...areaType, point: 'lightgreen', fill: 'rgba(0, 255, 0, 0.33)' };
    if (areaType.name === 'Exclusion') return { ...areaType, point: 'red', fill: 'rgba(255, 0, 0, 0.33)' };
    return { ...areaType, point: 'grey', fill: 'rgba(133, 133, 133, 0.5)' };
  });

  public visualPoints = computed(() => {
    const points = this.points();

    return { polygon: points.map(([x, y]) => `${x} ${y}`), points };
  });

  public areaTypes = computed<AreaTypeWithColors>(
    () => this.availableTypes.find((type) => type.id === this.area.area_type_id) || this.availableTypes[0]
  );

  constructor(private sharedStore: SharedStore) {
    this.drop = this.drop.bind(this);
    this.drag = this.drag.bind(this);
  }

  ngOnInit() {
    this.points.set(JSON.parse(this.area.coordinates));
  }

  grab(event: MouseEvent, index: number) {
    this.pointToDrag = event.target as SVGElement;
    this.indexToDrag = index;
    addEventListener('mouseup', this.drop);
    addEventListener('mousemove', this.drag);
  }

  drag(event: MouseEvent) {
    if (!this.pointToDrag || this.indexToDrag === null) return;

    const points = [...this.points()];

    const newX = Math.max(0, Math.min(event.offsetX, this.maxWidth));
    const newY = Math.max(0, Math.min(event.offsetY, this.maxHeight));

    points[this.indexToDrag] = [newX, newY];
    this.points.set(points);
  }

  drop() {
    this.pointToDrag = null;
    removeEventListener('mouseup', this.drop);
    removeEventListener('mousemove', this.drag);
    this.areaChange.emit({ ...this.area, coordinates: JSON.stringify(this.points()) });
  }

  ngOnChanges() {
    this.points.set(JSON.parse(this.area.coordinates));
  }
}
