import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from '@shared/types';
import { QuickAction } from '../action-bar/action-bar.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
})
export class PageHeaderComponent {
  @Input() title = '';
  @Input() filterBy: DropdownOption[] = [];
  @Input() filterByLabel = this.tr.instant('shared_pageheader_filter_type');
  @Input() filterViews: string[] = [];
  @Input() quickActions: QuickAction[] = [];
  @Input() showFilterBar = true;
  @Input() showAllView = true;

  @Output() filterStringChanged = new EventEmitter<string>();
  @Output() filterViewChanged = new EventEmitter<string[]>();
  @Output() filterByChanged = new EventEmitter<any>();

  public filterString = '';
  public views: string[] = [];

  constructor(private tr: TranslateService) {}

  resetViews() {
    this.setFilterViews([]);
  }

  setFilterOrg(org: DropdownOption) {
    this.filterByChanged.emit(org?.value);
  }

  setFilterString() {
    this.filterStringChanged.emit(this.filterString);
  }

  setFilterViews(views: string[]) {
    this.views = views;
    this.filterViewChanged.emit(views);
  }
}
