<i
  [pTooltip]="value"
  tooltipPosition="top"
  [class]="'pi ' + value"
  [ngClass]="{
    'pi-check': value === IconStatus.ACTIVE || value === IconStatus.SUCCESS,
    'pi-info': value === IconStatus.INACTIVE || value === IconStatus.FAILURE,
    'pi-hourglass': value === IconStatus.PENDING || value === IconStatus.INPROGRESS,
    'pi-circle-off': value === IconStatus.DISABLED,
    'pi-minus-circle': value === IconStatus.IDLE,
  }"
>
</i>
