import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private tokenService: TokenService, private router: Router) {}

  resolveLoginToken() {
    const currentUrl = window.location.href.replace('#id_token', '&id_token');
    const tokenInUrl = new URLSearchParams(currentUrl).get('id_token');
    const tokenInStorage = this.tokenService.token.getFromStorage();

    if (tokenInUrl) {
      const scrubbedUrl = this.scrubAWSLoginParamsFromUrl(new URL(currentUrl));

      this.tokenService.token.set(tokenInUrl);
      this.router.navigateByUrl(scrubbedUrl.pathname, { replaceUrl: true });
      return;
    } else if (tokenInStorage) {
      this.tokenService.token.set(tokenInStorage);
      if (!this.tokenService.isLoginExpired()) return;
    }
    this.sendToLogin();
  }

  private scrubAWSLoginParamsFromUrl(url: URL): URL {
    url.searchParams.delete('id_token');
    url.searchParams.delete('access_token');
    url.searchParams.delete('expiry');
    return url;
  }

  login() {
    this.tokenService.token.remove();
    this.sendToLogin();
  }

  logout() {
    this.tokenService.token.remove();
    this.sendToLogout();
  }

  private sendToLogin() {
    const redirectUrl = encodeURI(`${window.location.origin}`);
    window.location.href = `${environment.authUrl}/login?response_type=token&client_id=${environment.clientId}&redirect_uri=${redirectUrl}`;
  }

  private sendToLogout() {
    const redirectUrl = encodeURI(`${window.location.origin}`);
    window.location.href = `${environment.authUrl}/logout?client_id=${environment.clientId}&logout_uri=${redirectUrl}`;
  }
}
