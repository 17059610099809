<div>
  <label for="{{ placeholder }}">{{ placeholder }}</label>
  <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
</div>
<p-calendar
  [timeOnly]="true"
  [stepMinute]="15"
  [appendTo]="'body'"
  [dataType]="'string'"
  [defaultDate]="defaultDate"
  [(ngModel)]="time"
  (ngModelChange)="valueChanged()"
/>
<small *ngIf="control.invalid && control.dirty" class="error-label"> {{ errorLabel }} </small>
