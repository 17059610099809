import { Component, Input } from '@angular/core';

type MenuOption = { label: string; icon?: string; command?: () => void };

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrl: './context-menu.component.scss',
})
export class ContextMenuComponent {
  @Input() options: MenuOption[] = [];
}
