<ng-container *ngIf="actions.length > 0">
  <p-accordion [activeIndex]="open">
    <p-accordionTab>
      <app-button
        *ngFor="let action of actions"
        [disabled]="action.disabled"
        [value]="action.label"
        [severity]="'secondary'"
        (click)="!action.disabled && action.command()"
      />
    </p-accordionTab>
  </p-accordion>
  <div class="pull-down" (click)="openToggle()">
    <i class="bi bi-chevron-double-down" [ngClass]="{ open: open === 0 }"></i>
    <span class="text-capitalize">{{ "shared_actionbar_quickaction" | translate }}</span>
    <i class="bi bi-chevron-double-down" [ngClass]="{ open: open === 0 }"></i>
  </div>
</ng-container>
