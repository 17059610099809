<div>
  <label for="{{ placeholder }}">{{ placeholder }}</label>
  <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
</div>
<p-calendar
  dataType="string"
  [(ngModel)]="date"
  [showIcon]="true"
  [id]="placeholder"
  [appendTo]="'body'"
  [showOnFocus]="readOnly"
  [dateFormat]="'yy-mm-dd'"
  [readonlyInput]="readOnly"
  (onSelect)="valueChanged()"
  [firstDayOfWeek]="1"
  [showTime]="showTime"
  [hourFormat]="hourFormat"
  [minDate]="minDate"
/>

<small *ngIf="control.invalid && control.dirty" class="error-label"> {{ errorLabel }} </small>
