import { Component, Input } from '@angular/core';

export type QuickAction = { label: string; disabled: boolean; command: () => void };

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrl: './action-bar.component.scss',
})
export class ActionBarComponent {
  @Input() actions: QuickAction[] = [];

  public open = 1;

  openToggle() {
    this.open = this.open === 0 ? 1 : 0;
  }
}
