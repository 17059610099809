import { Component, EventEmitter, HostBinding, Input, Output, Signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';

@Component({
  selector: 'app-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrl: './form-toggle.component.scss',
})
export class FormToggleComponent {
  @HostBinding('style.flexDirection') get flexDirection() {
    return this.getFlexDirection();
  }

  @Input() label = '';
  @Input() control?: FormControl;
  @Input() signal?: Signal<boolean>;
  @Input() tooltip = '';
  @Input() disabled: boolean = false;
  @Input() labelPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';

  @Output() valueChange = new EventEmitter<boolean>();

  public valueChanged(event: InputSwitchChangeEvent) {
    this.valueChange.emit(event.checked);
  }

  private getFlexDirection(): string {
    switch (this.labelPosition) {
      case 'top':
        return 'column';
      case 'right':
        return 'row-reverse';
      case 'left':
        return 'row';
      case 'bottom':
        return 'column-reverse';
      default:
        return 'column';
    }
  }
}
