<span *ngIf="editable" style="position: absolute; color: wheat">Editing area: {{ area.name }}</span>
<svg id="area-{{ area.id }}" height="100%" width="100%">
  <polygon
    [attr.stroke-width]="editable ? 3 : 1"
    [attr.points]="visualPoints().polygon"
    [ngStyle]="{ stroke: areaTypes().point, fill: areaTypes().fill }"
  />
  <circle
    *ngFor="let point of visualPoints().points; let i = index"
    (mousedown)="grab($event, i)"
    class="draggable"
    [attr.cx]="point[0]"
    [attr.cy]="point[1]"
    [attr.fill]="areaTypes().point"
    r="6"
  />
</svg>
