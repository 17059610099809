import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-helper-icon',
  templateUrl: './helper-icon.component.html',
  styleUrl: './helper-icon.component.scss',
})
export class HelperIconComponent {
  @Input() public text: string = '';
  @Input() public position: 'top' | 'bottom' | 'left' | 'right' = 'top';
}
