import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-calendar',
  templateUrl: './form-calendar.component.html',
  styleUrl: './form-calendar.component.scss',
})
export class FormCalendarComponent implements OnInit {
  @Input() placeholder = '';
  @Input() errorLabel = '';
  @Input() control: FormControl = new FormControl();
  @Input() initialDate?: string;
  @Input() tooltip?: string;
  @Input() readOnly = false;
  @Input() showTime = false;
  @Input() hourFormat = '';
  @Input() minDate?: Date;

  @Output() valueChange = new EventEmitter<string>();

  date: string = '';

  ngOnInit() {
    this.date = this.initialDate ?? '';
  }

  public valueChanged() {
    const UTCTime = new Date(new Date(this.date).toUTCString()).toISOString();
    this.valueChange.emit(UTCTime);
  }

  public reset() {
    this.date = '';
    this.valueChange.emit('');
  }
}
