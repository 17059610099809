import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { SharedStore } from '@shared/services';

@Directive({ selector: '[hasPermission]' })
export class AllowFromPermissionDirective implements OnChanges {
  @Input() hasPermission: string = '';
  public user = this.sharedStore.user.get();

  allowed_permissions = ['get', 'create', 'update', 'delete', 'import'];

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private sharedStore: SharedStore
  ) {}

  ngOnChanges() {
    if (!this.allowed_permissions.includes(this.hasPermission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    if (this.allowed_permissions.includes(this.hasPermission)) {
      const permissions = this.sharedStore.authorizedRoutes.get().map((route) => route.toLowerCase());

      this.viewContainer.clear();

      if (this.user.isSystemAdmin || permissions.find((permission) => permission === `can_${this.hasPermission}`)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }
}
