import { Component, Input, model } from '@angular/core';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.scss',
})
export class ButtonGroupComponent {
  @Input() options: string[] = [];
  @Input() multiple = true;

  public selected = model<string[]>([]);

  onButtonChange(options: string[] | string) {
    if (Array.isArray(options)) {
      this.selected.set(options);
      return;
    }
    this.selected.set([options]);
  }
}
