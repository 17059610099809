<p-meterGroup [value]="meterTimes">
  <ng-template pTemplate="label" />
  <ng-template pTemplate="meter" let-value let-class="class" let-width="size">
    <span
      [pTooltip]="value.label"
      [tooltipPosition]="'top'"
      [class]="class"
      [style]="{ background: value.color, width: width }"
    ></span>
  </ng-template>
</p-meterGroup>
