import { Component, EventEmitter, Input, OnChanges, Output, Signal, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from '@shared/types';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
})
export class MultiSelectComponent implements OnChanges {
  @Input() label = '';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() tooltip?: string;
  @Input() signal?: Signal<DropdownOption[]>;
  @Input() options: DropdownOption[] = [];
  @Input() selectedOptionValue?: string | number;
  @Input() placeholder = this.tr.instant('shared_dropdown_placeholder');
  @Input() errorMessage = this.tr.instant('shared_dropdown_errorMessage');

  @Output() changed = new EventEmitter<DropdownOption[]>();

  public invalid = false;
  public chosenOption?: DropdownOption;

  constructor(private tr: TranslateService) {}

  public selectOption(option: DropdownOption[]) {
    this.invalid = false;
    this.changed.emit(option);
  }

  public onHide() {
    this.invalid = this.required && !this.chosenOption;
  }

  public reset() {
    this.chosenOption = this.options.find((option) => option.value === this.selectedOptionValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']?.currentValue !== changes['options']?.previousValue) {
      this.chosenOption = this.options.find((option) => option.value === this.selectedOptionValue);
    }
  }
}
