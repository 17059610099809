import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(private http: HttpClient) {}

  get<T>(url: string, responseType = 'json' as const) {
    return this.http.get<T>(`${environment.apiUrl}/${url}`, { responseType });
  }

  delete<T>(url: string, responseType = 'json' as const) {
    return this.http.delete<T>(`${environment.apiUrl}/${url}`, { responseType });
  }

  post<T>(url: string, body: unknown, responseType = 'json' as const) {
    return this.http.post<T>(`${environment.apiUrl}/${url}`, body, { responseType });
  }

  patch<T>(url: string, body: unknown, responseType = 'json' as const) {
    return this.http.patch<T>(`${environment.apiUrl}/${url}`, body, { responseType });
  }
}
