<div class="d-flex flex-column">
  <div *ngIf="label">
    <label for="select">{{ label }}</label> <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
  </div>
  <p-multiSelect
    *ngIf="!signal"
    id="select"
    appendTo="body"
    [options]="options"
    [loading]="loading"
    [optionLabel]="'name'"
    [disabled]="disabled"
    [placeholder]="loading ? ('shared_dropdown_loading' | translate) : placeholder"
    [ngClass]="{ 'ng-dirty ng-invalid': invalid }"
    [(ngModel)]="signal"
    (onHide)="onHide()"
    (onChange)="selectOption($event.value)"
  />
  <p-multiSelect
    *ngIf="signal"
    id="select"
    appendTo="body"
    [options]="options"
    [loading]="loading"
    [optionLabel]="'name'"
    [disabled]="disabled"
    [placeholder]="loading ? ('shared_dropdown_loading' | translate) : placeholder"
    [ngClass]="{ 'ng-dirty ng-invalid': invalid }"
    [(ngModel)]="signal"
  />

  <small *ngIf="invalid">{{ errorMessage }}</small>
</div>
