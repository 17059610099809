import { Injectable } from '@angular/core';
import { Organization } from '@shared/types';
import { HttpService } from './http.service';
import { BaseSettings, SharedStore } from './shared.store';

@Injectable({ providedIn: 'root' })
export class SharedService {
  constructor(private http: HttpService, private sharedStore: SharedStore) {}

  initializeStore(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<BaseSettings>('basesettings').subscribe({
        next: (settings) => {
          this.sharedStore.base.set(settings);
          resolve();
        },
        error: (error) => reject(error),
      });

      if (this.sharedStore.user.get().isSystemAdmin) {
        this.http.get<Organization[]>('organizations').subscribe({
          next: (organizations) => this.sharedStore.organizations.set(organizations),
          error: (error) => reject(error),
        });
      }
    });
  }
}
