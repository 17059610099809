import { Component, Input } from '@angular/core';

type Severity = 'success' | 'info' | 'warning' | 'danger' | 'help' | 'primary' | 'secondary' | 'contrast';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() value = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() outlined = false;
  @Input() severity: Severity = 'primary';
  @Input() text = false;
  @Input() plain = false;
  @Input() icon = '';
}
