<p-table
  #pTable
  dataKey="id"
  [paginator]="enablePagination && data.length > 10"
  [rows]="10"
  [value]="data"
  [loading]="loading"
  [scrollable]="true"
  [rowHover]="rowHover"
  [sortField]="sortField"
  [sortOrder]="1"
  [rowsPerPageOptions]="[10, 25, 50]"
  [(selection)]="selectedItems"
  (selectionChange)="onSelection()"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="expandable"></th>
      <th *ngIf="selectable"><p-tableHeaderCheckbox /></th>
      <th
        *ngFor="let column of columns"
        [pSortableColumn]="column.value"
        [pSortableColumnDisabled]="column.sortable === false"
      >
        <ng-container>
          {{ column.name | translate }}<p-sortIcon [field]="column.value" *ngIf="column.sortable !== false"
        /></ng-container>
      </th>
      <th *ngIf="actions.length > 0"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="loadingbody">
    <tr>
      <td
        [colSpan]="columns.length + (selectable ? 1 : 0) + (expandable ? 1 : 0) + (!!actions ? 1 : 0)"
        style="text-align: center"
      >
        {{ loadingMessage }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [colSpan]="columns.length + (selectable ? 1 : 0) + (expandable ? 1 : 0) + (!!actions ? 1 : 0)"
        style="text-align: center"
      >
        {{ emptyMessage }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-expanded="expanded">
    <tr>
      <td *ngIf="expandable" class="p-2" style="width: 1rem">
        <p-button
          *ngIf="item.nestedItem?.length > 0"
          type="button"
          [text]="true"
          [plain]="true"
          [pRowToggler]="item"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          class="expander"
        />
      </td>
      <td *ngIf="selectable" style="width: 1rem">
        <p-tableCheckbox [value]="item" />
      </td>
      <td *ngFor="let column of columns">
        <ng-container *ngIf="type === 'default'">
          <ng-container *ngIf="(column.name | translate) !== 'Status'">{{ item[column.value] }}</ng-container>
          <app-status-icon *ngIf="(column.name | translate) === 'Status'" [value]="item[column.value]" />
        </ng-container>
        <ng-container *ngIf="type === 'summary'">
          <app-table-camera-detection
            *ngIf="column.value !== 'timeframe'; else timeFrame"
            [input]="item[column.value]"
          />
          <ng-template #timeFrame>{{ item[column.value] }}</ng-template>
        </ng-container>
      </td>
      <td *ngIf="actions.length > 0" style="width: 1rem">
        <app-context-menu [options]="actions" (click)="onRowContextSelect(item)" />
      </td>
    </tr>
    <div *ngIf="!expanded" class="spacer"></div>
  </ng-template>

  <ng-template *ngIf="expandable" pTemplate="rowexpansion" let-item>
    <tr class="spacer">
      <td class="p-0" [colSpan]="columns.length + (selectable ? 1 : 0) + (expandable ? 1 : 0) + (!!actions ? 1 : 0)">
        <p-table [value]="item.nestedItem" dataKey="id" styleClass="striped">
          <ng-template pTemplate="body" let-subItem>
            <tr>
              <td *ngIf="expandable" style="width: 48px"></td>
              <td *ngIf="selectable" style="width: 52px"></td>
              <td *ngFor="let column of expandColumns">
                <ng-container *ngIf="(column.name | translate) !== 'Status'">
                  {{ subItem[column.value] }}
                </ng-container>
                <app-status-icon *ngIf="(column.name | translate) === 'Status'" [value]="subItem[column.value]" />
              </td>
              <td *ngIf="expandActions.length > 0" style="width: 1rem">
                <app-context-menu [options]="expandActions" (click)="onRowContextSelect(subItem)" />
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td *ngIf="expandable" style="width: 48px"></td>
              <td *ngIf="selectable" style="width: 52px"></td>
              <td colspan="6">{{ expandEmptyMessage }}</td>
            </tr>
          </ng-template>
        </p-table>
      </td>
    </tr>
    <div class="spacer"></div>
  </ng-template>
</p-table>
