import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-time-picker',
  templateUrl: './form-time-picker.component.html',
  styleUrl: './form-time-picker.component.scss',
})
export class FormTimePickerComponent implements OnInit {
  @Input() placeholder = '';
  @Input() errorLabel = '';
  @Input() control: FormControl = new FormControl();
  @Input() initialDate?: string;
  @Input() tooltip?: string;
  @Input() readOnly = false;

  @Output() valueChange = new EventEmitter<string>();

  time: string | undefined;
  defaultDate = new Date(new Date().setHours(8, 0, 0, 0));

  ngOnInit() {
    this.time = this.initialDate ??= this.defaultDate.toISOString();
  }

  valueChanged() {
    this.valueChange.emit(this.time);
  }
}
