<p-button
  [text]="text"
  [plain]="plain"
  [severity]="severity"
  [label]="value"
  [outlined]="outlined"
  [disabled]="disabled"
  [loading]="loading"
  [icon]="icon"
/>
