import { Pipe, PipeTransform } from '@angular/core';

type CalendarDate = { day: number; month: number; year: number; today: boolean; selectable: boolean };

@Pipe({ name: 'isPartOfWeek', pure: true })
export class IsPartOfWeekPipe implements PipeTransform {
  transform(currentlySelectedWeek: string, dateToCheck: CalendarDate): boolean {
    const weekNumber = parseInt(currentlySelectedWeek.split(' ')[1]);
    const currentDay = new Date(dateToCheck.year, dateToCheck.month, dateToCheck.day);

    const startOfWeek = this.getStartOfWeekfromWeekNumber(weekNumber, dateToCheck.year);
    const endOfWeek = this.getSundayFromWeekNum(weekNumber, dateToCheck.year);

    return currentDay >= startOfWeek && currentDay <= endOfWeek;
  }

  private getStartOfWeekfromWeekNumber(weekNum: number, year: number): Date {
    const date = new Date(year, 0, 1 + (weekNum - 1) * 7);
    date.setDate(date.getDate() + (1 - date.getDay()));
    return date;
  }

  private getSundayFromWeekNum(weekNum: number, year: number) {
    const date = new Date(year, 0, 1 + (weekNum - 1) * 7);
    date.setDate(date.getDate() + (7.9 - date.getDay()));
    return date;
  }
}
