import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MeterGroupModule } from 'primeng/metergroup';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { MyMissingTranslationHandler } from '../missingTranslationHandler';
import {
  ActionBarComponent,
  ButtonComponent,
  ButtonGroupComponent,
  ContextMenuComponent,
  DropdownComponent,
  FormCalendarComponent,
  FormCheckboxComponent,
  FormInputComponent,
  FormTimePickerComponent,
  FormToggleComponent,
  FormWeekCalendarComponent,
  HelperIconComponent,
  NavbarComponent,
  PageHeaderComponent,
  PolygonShapeComponent,
  SpinnerComponent,
  StatusIconComponent,
  SvgComponent,
  TableCameraDetectionComponent,
  TableComponent,
  TimeDayScaleComponent,
  TreeTableComponent,
} from './components';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { AllowFromListDirective, AllowFromPermissionDirective } from './directives';
import { IsPartOfWeekPipe } from './pipes';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const PrimeNGModules = [
  ButtonModule,
  TableModule,
  ProgressSpinnerModule,
  DynamicDialogModule,
  ConfirmDialogModule,
  InputTextModule,
  FloatLabelModule,
  DropdownModule,
  MenuModule,
  PanelMenuModule,
  PanelModule,
  BadgeModule,
  IconFieldModule,
  InputIconModule,
  SelectButtonModule,
  AccordionModule,
  KeyFilterModule,
  InputSwitchModule,
  CalendarModule,
  TooltipModule,
  CheckboxModule,
  MeterGroupModule,
  ToastModule,
  TreeTableModule,
  MultiSelectModule,
];

const HallMonitorComponents = [
  ActionBarComponent,
  NavbarComponent,
  PageHeaderComponent,
  SvgComponent,
  TableComponent,
  ButtonComponent,
  SpinnerComponent,
  DropdownComponent,
  ContextMenuComponent,
  ButtonGroupComponent,
  StatusIconComponent,
  FormInputComponent,
  FormToggleComponent,
  FormTimePickerComponent,
  FormWeekCalendarComponent,
  AllowFromListDirective,
  AllowFromPermissionDirective,
  IsPartOfWeekPipe,
  FormCalendarComponent,
  HelperIconComponent,
  TimeDayScaleComponent,
  PolygonShapeComponent,
  TreeTableComponent,
  TableCameraDetectionComponent,
  MultiSelectComponent,
  FormCheckboxComponent,
];

@NgModule({
  declarations: [HallMonitorComponents],
  exports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, HallMonitorComponents, PrimeNGModules],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgOptimizedImage,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
    PrimeNGModules,
  ],
  providers: [DialogService, ConfirmationService, MessageService, provideHttpClient(withInterceptorsFromDi())],
})
export class SharedModules {}
